img.projects-top{
    width: 80%;
    height: 80%;
    object-fit: cover;

}

@media screen and (max-width: 1100px){
    img.projects-top{
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-top: 200px;
    }
    
    .hero-bottom{
        display: none;
    }

    .computer{
        height: 550px;
}
}

@media screen and (max-width: 768px){
    img.projects-top{
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-top: 120px;
    }

    .hero-top{
        height: 200px;
        width: 200px;
    }

    .computer{
        height: 350px;
    }
    
}